import { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useMatch } from "react-router-dom";

import {
  BusinessCenter,
  InsertChartOutlined,
  KeyboardArrowDown,
  Map,
  Menu,
  PlaylistAdd,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Link,
  List,
  styled,
  SvgIconTypeMap,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { AuthContext } from "@contexts/Authentication";
import { CustomerContext } from "@contexts/Customer";

import { Breadcrumbs } from "@components/Breadcrumbs";
import { UserMenu } from "@components/UserMenu";

import { AerialCompanyPage } from "@pages/AerialCompany";
import { CustomerUsersPage } from "@pages/CustomerUsers";
import { ProductsPage } from "@pages/Products";
import { PropertiesPage } from "@pages/Properties";
import { ReportsPage } from "@pages/Reports";
import { SeasonPage } from "@pages/Season";
import { ServiceOrdersPage } from "@pages/ServiceOrders";

import { theme } from "@theme/index";

interface SidebarDrawerProps {
  children: JSX.Element;
}

const CLOSE_DRAWER = 104;
const OPEN_DRAWER = 315;
type AccordionType = "REGISTRATION" | "REPORTS";

export const SidebarDrawer = ({ children }: SidebarDrawerProps) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { isCurrentUserPilot } = useContext(AuthContext);
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState<AccordionType | null>(null);

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      const current = sidebarRef?.current as HTMLDivElement;
      const outsideClick = !current?.contains(event.target);

      if (outsideClick) {
        setIsDrawerOpen(false);
        setExpanded(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${CLOSE_DRAWER}px)` },
          ml: { sm: `${CLOSE_DRAWER}px` },
          height: theme.spacing(4),
          backgroundColor: theme.palette.grey[50],
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            padding: "0.5rem 1rem 0.5rem 0.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.625rem",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Breadcrumbs />
          </Box>
          <Box>
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: CLOSE_DRAWER },
          flexShrink: { sm: 0 },
        }}
        aria-label="sidebar drawer"
      >
        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: OPEN_DRAWER,
              borderRight: "none",
              boxShadow:
                "1px 0px 0px 0px #E0E0E0, 3px 0px 7px 0px rgba(224, 224, 224, 0.40)",
              overflowX: "hidden",
            },
          }}
        >
          <OptionsDrawer
            intl={intl}
            customerId={customerId}
            isMobile={isMobile}
            isDrawerOpen={isDrawerOpen}
            onOpenDrawer={() => setIsDrawerOpen(true)}
            expanded={expanded}
            onUpdateExpanded={setExpanded}
            isCurrentUserPilot={isCurrentUserPilot}
          />
        </Drawer>
        <Drawer
          open
          ref={sidebarRef}
          variant="permanent"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: isDrawerOpen && !isMobile ? OPEN_DRAWER : CLOSE_DRAWER,
              borderRight: "none",
              boxShadow:
                "1px 0px 0px 0px #E0E0E0, 3px 0px 7px 0px rgba(224, 224, 224, 0.40)",
              overflowX: "hidden",
              transition: `${theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              })}`,
            },
          }}
        >
          <OptionsDrawer
            intl={intl}
            customerId={customerId}
            isMobile={isMobile}
            isDrawerOpen={isDrawerOpen}
            onOpenDrawer={() => setIsDrawerOpen(true)}
            expanded={expanded}
            onUpdateExpanded={setExpanded}
            isCurrentUserPilot={isCurrentUserPilot}
          />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: `${theme.spacing(4)} 0 0 0`,
          width: { sm: `calc(100% - ${CLOSE_DRAWER}px)`, xs: "100%" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const OptionsDrawer = ({
  intl,
  customerId,
  isMobile,
  isDrawerOpen,
  onOpenDrawer,
  expanded,
  onUpdateExpanded,

  isCurrentUserPilot,
}: {
  intl: IntlShape;
  customerId: string;
  isMobile: boolean;
  isDrawerOpen: boolean;
  onOpenDrawer: () => void;
  expanded: AccordionType | null;
  onUpdateExpanded: (accordion: AccordionType | null) => void;
  isCurrentUserPilot: boolean;
}) => {
  const propertiesUrl = useMatch("/customers/:customerId/properties/*");
  const serviceOrderUrl = useMatch("/customers/:customerId/service-orders/*");
  const registrationUrl = useMatch("/customers/:customerId/registration/*");
  const reportsUrl = useMatch("/customers/:customerId/reports/*");

  const handleChangeAccordion =
    (panel: AccordionType) => (_: unknown, isExpanded: boolean) => {
      onOpenDrawer();
      onUpdateExpanded(isExpanded ? panel : null);
    };

  return (
    <Box sx={{ padding: theme.spacing(0.92) }}>
      <Toolbar
        sx={{ padding: `0 0 0 ${theme.spacing(1)}`, justifyContent: "center" }}
      >
        {isDrawerOpen ? (
          <img src={"/images/pf_full.svg"} alt={"logo-full"} />
        ) : (
          <img src={"/images/pf_small.svg"} alt={"logo-small"} />
        )}
      </Toolbar>
      <List>
        <ListItem
          isActive={!!serviceOrderUrl}
          isDrawerOpen={isDrawerOpen}
          label={intl.formatMessage({
            defaultMessage: "Ordem de Serviço",
            id: "8JEUOY",
            description: "service order label",
          })}
          href={ServiceOrdersPage.createPath(customerId)}
          Icon={BusinessCenter}
        />

        {!isCurrentUserPilot && (
          <ListItem
            isActive={!!propertiesUrl}
            isDrawerOpen={isDrawerOpen}
            label={intl.formatMessage({
              defaultMessage: "Propriedades",
              id: "Jq7xGZ",
              description: "properties label",
            })}
            href={PropertiesPage.createPath(customerId)}
            Icon={Map}
          />
        )}

        {!isCurrentUserPilot && (
          <Accordion
            expanded={expanded === "REGISTRATION" || isMobile}
            onChange={handleChangeAccordion("REGISTRATION")}
          >
            <AccordionSummary
              aria-controls="registration-content"
              id="registration-header"
              isActive={!!registrationUrl}
            >
              <Box sx={{ pl: 0 }}>
                <PlaylistAddIcon isActive={!!registrationUrl} />
              </Box>

              {isDrawerOpen && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ ml: theme.spacing(2) }}
                    color={
                      registrationUrl
                        ? theme.palette.text.primary
                        : theme.palette.grey[700]
                    }
                  >
                    <FormattedMessage
                      defaultMessage="Cadastros"
                      id="hvRwh5"
                      description="registrations label"
                    />
                  </Typography>

                  <KeyboardArrowDownIcon />
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <AccordionItemLink href={SeasonPage.createPath(customerId)}>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  <FormattedMessage
                    defaultMessage="Safras"
                    id="l84Qa7"
                    description="seasons label"
                  />
                </Typography>
              </AccordionItemLink>
              <AccordionItemLink href={ProductsPage.createPath(customerId)}>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  <FormattedMessage
                    defaultMessage="Produtos"
                    id="7dCiN0"
                    description="products label"
                  />
                </Typography>
              </AccordionItemLink>
              <AccordionItemLink
                href={AerialCompanyPage.createPath(customerId)}
              >
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  <FormattedMessage
                    defaultMessage="Provedor de Serviços"
                    id="5QedKI"
                    description="service providers label"
                  />
                </Typography>
              </AccordionItemLink>
              <AccordionItemLink
                href={CustomerUsersPage.createPath(customerId)}
              >
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  <FormattedMessage
                    defaultMessage="Usuários"
                    id="vMf3pL"
                    description="users label"
                  />
                </Typography>
              </AccordionItemLink>
            </AccordionDetails>
          </Accordion>
        )}

        {!isCurrentUserPilot && (
          <Accordion
            expanded={expanded === "REPORTS" || isMobile}
            onChange={handleChangeAccordion("REPORTS")}
          >
            <AccordionSummary
              aria-controls="reports-content"
              id="reports-header"
              isActive={!!reportsUrl}
            >
              <Box sx={{ pl: 0 }}>
                <InsertChartOutlinedIcon isActive={!!reportsUrl} />
              </Box>
              {isDrawerOpen && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ ml: theme.spacing(2) }}
                    color={
                      reportsUrl
                        ? theme.palette.text.primary
                        : theme.palette.grey[700]
                    }
                  >
                    <FormattedMessage
                      defaultMessage="Relatórios"
                      id="Ydbrin"
                      description="reports label"
                    />
                  </Typography>

                  <KeyboardArrowDownIcon />
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <AccordionItemLink href={ReportsPage.createPath(customerId)}>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  <FormattedMessage
                    defaultMessage="Análise"
                    id="J8UMOF"
                    description="analytics label"
                  />
                </Typography>
              </AccordionItemLink>
            </AccordionDetails>
          </Accordion>
        )}
      </List>
    </Box>
  );
};

const ListItem = ({
  isActive,
  isDrawerOpen,
  label,
  href,
  Icon,
}: {
  isActive: boolean;
  isDrawerOpen: boolean;
  label: string;
  href: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
}) => {
  return (
    <ListItemWrapper isActive={isActive}>
      <ItemLink href={href} isDrawerOpen={isDrawerOpen}>
        <ItemIcon>
          <Icon
            sx={{
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.grey[400],
            }}
          />
          {isDrawerOpen && (
            <Typography
              variant="body1"
              color={
                isActive ? theme.palette.text.primary : theme.palette.grey[700]
              }
            >
              {label}
            </Typography>
          )}
        </ItemIcon>
      </ItemLink>
    </ListItemWrapper>
  );
};

const ListItemWrapper = styled(Box)<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? theme.palette.grey[100] : "transparent"};
  border-radius: ${(props) => props.theme.spacing(0.25)};

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  padding: 0,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionItemLink = styled(Link)`
  cursor: pointer;
  display: flex;
  margin-left: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(3.688)};
  background-color: "transparent";
  height: ${(props) => props.theme.spacing(2.813)};
  align-items: center;
  border-radius: ${(props) => props.theme.spacing(0.5)};
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)<{ isActive: boolean }>`
  width: 100%;
  height: ${(props) => props.theme.spacing(4.375)};
  min-height: ${(props) => props.theme.spacing(4.375)};
  background-color: ${(props) =>
    props.isActive ? theme.palette.grey[100] : "transparent"};
  border-radius: ${(props) => props.theme.spacing(0.25)};
  flex-direction: row;
  padding: ${(props) => props.theme.spacing(0, 1.563)};

  "& .muiaccordionsummary-content": {
    display: flex;
    margin-left: ${(props) => props.theme.spacing(0.25)};

    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const ItemLink = styled(Link)<{
  isDrawerOpen: boolean;
}>`
  text-decoration: none;
  border-radius: ${(props) => props.theme.spacing(0.25)};
  justify-content: ${(props) => (props.isDrawerOpen ? "left" : "center")};
  cursor: pointer;
`;

const ItemIcon = styled(Box)`
  display: flex;
  height: ${(props) => props.theme.spacing(4.375)};
  padding: ${(props) => props.theme.spacing(0.9375, 1.5625)};
  gap: ${(props) => props.theme.spacing(1.94)};
  align-items: center;
`;

const PlaylistAddIcon = styled(PlaylistAdd)<{ isActive: boolean }>`
  width: ${(props) => props.theme.spacing(1.5)};
  height: ${(props) => props.theme.spacing(1.5)};
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[400]};
`;

const InsertChartOutlinedIcon = styled(InsertChartOutlined)<{
  isActive: boolean;
}>`
  width: ${(props) => props.theme.spacing(1.5)};
  height: ${(props) => props.theme.spacing(1.5)};
  color: ${(props) =>
    props.isActive
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[400]};
`;

const MenuIcon = styled(Menu)`
  width: ${(props) => props.theme.spacing(1.5)};
  height: ${(props) => props.theme.spacing(1.5)};
  color: ${(props) => props.theme.palette.grey[700]};
`;

const KeyboardArrowDownIcon = styled(KeyboardArrowDown)`
  width: ${(props) => props.theme.spacing(1.5)};
  height: ${(props) => props.theme.spacing(1.5)};
  color: ${(props) => props.theme.palette.grey[700]};
`;
