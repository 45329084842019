import { gql } from "@apollo/client";

export const LIST_FARMS = gql`
  query ListFarms($input: ListFarmsInput!) {
    farms(input: $input) {
      farms {
        id
        name
        namespace
        hasChildren
        hasFields
        totalChildren
        totalFields
        totalArea
        parentId
        geometry {
          type
          coordinates
        }
      }
      paging {
        offset
        limit
        total
      }
    }
  }
`;

export const LIST_FARMS_ID_NAME = gql`
  query ListFarmsIdName($farmIdNameInput: ListFarmsInput!) {
    farms(input: $farmIdNameInput) {
      farms {
        id
        name
      }
    }
  }
`;

export const GET_FARM = gql`
  query GetFarm($input: FindFarmInput!) {
    farm(input: $input) {
      id
      name
    }
  }
`;
