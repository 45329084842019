import { gql } from "@apollo/client";

export const LIST_RESTRICTED_LOCATIONS = gql`
  query ListRestrictedLocations(
    $restrictedLocationsInput: ListRestrictedLocationsInput!
  ) {
    restrictedLocations(input: $restrictedLocationsInput) {
      restrictedLocations {
        id
        customerId
        type
        externalId
        warningRadius
        geometry {
          type
          coordinates
        }
        createdAt
        updatedAt
        deletedAt
      }
      paging {
        offset
        limit
        total
      }
    }
  }
`;

export const LIST_RESTRICTED_LOCATIONS_BY_FIELDS = gql`
  query ListRestrictedLocationsByFields(
    $input: ListRestrictedLocationsByFieldsInput!
  ) {
    restrictedLocationsByFields(input: $input) {
      id
      customerId
      type
      externalId
      warningRadius
      geometry {
        type
        coordinates
      }
    }
  }
`;

export const LIST_RESTRICTED_LOCATION_TYPES = gql`
  query ListRestrictedLocationTypes {
    restrictedLocationTypes {
      id
      name
      warningRadius
      translations {
        br
        es
      }
    }
  }
`;
