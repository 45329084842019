import { defineMessages } from "react-intl";

export const CropMessages = defineMessages({
  Avocado: {
    defaultMessage: "Abacate",
    id: "YmFou5",
    description: "avocado label",
  },
  Bean: {
    defaultMessage: "Feijão",
    id: "HMrraY",
    description: "bean label",
  },
  Brachiaria: {
    defaultMessage: "Braquiária",
    id: "u8Oui8",
    description: "brachiaria label",
  },
  Cereals: {
    defaultMessage: "Cereais",
    id: "ltDlY/",
    description: "cereals label",
  },
  Citrus: {
    defaultMessage: "Cítrico",
    id: "Rj7NqP",
    description: "citrus label",
  },
  Cocoa: {
    defaultMessage: "Cacau",
    id: "KZCry8",
    description: "cocoa label",
  },
  Coconut: {
    defaultMessage: "Coco",
    id: "WUYzJZ",
    description: "coconut label",
  },
  Coffee: {
    defaultMessage: "Café",
    id: "PeOFWw",
    description: "coffee label",
  },
  Corn: {
    defaultMessage: "Milho",
    id: "YywgaO",
    description: "corn label",
  },
  Cotton: {
    defaultMessage: "Algodão",
    id: "djqqoZ",
    description: "cotton label",
  },
  Cover: {
    defaultMessage: "Cobertura",
    id: "BMOT/2",
    description: "cover label",
  },
  Eucalyptus: {
    defaultMessage: "Eucalipto",
    id: "ZCfe9n",
    description: "eucalyptus label",
  },
  Grape: {
    defaultMessage: "Uva",
    id: "9u1P7S",
    description: "grape label",
  },
  Oat: {
    defaultMessage: "Aveia",
    id: "2dGWi1",
    description: "oat label",
  },
  Orange: {
    defaultMessage: "Laranja",
    id: "mfIlTY",
    description: "orange label",
  },
  Peanut: {
    defaultMessage: "Amendoim",
    id: "9Q8tru",
    description: "peanut label",
  },
  PearlMillet: {
    defaultMessage: "Milheto Pérola",
    id: "MReKTs",
    description: "pearl millet label",
  },
  Pineapple: {
    defaultMessage: "Abacaxi",
    id: "I//xTo",
    description: "pineapple label",
  },
  Potato: {
    defaultMessage: "Batata",
    id: "Yhd2Sq",
    description: "potato label",
  },
  Rice: {
    defaultMessage: "Arroz",
    id: "7x7iDW",
    description: "rice label",
  },
  Sorghum: {
    defaultMessage: "Sorgo",
    id: "nQGSBL",
    description: "sorghum label",
  },
  Soybean: {
    defaultMessage: "Soja",
    id: "5Stu8y",
    description: "soybean label",
  },
  Stylosanthes: {
    defaultMessage: "Estilosante",
    id: "BmldVH",
    description: "stylosanthes label",
  },
  Sugarcane: {
    defaultMessage: "Cana de açúcar",
    id: "L3zASc",
    description: "sugarcane label",
  },
  Sunflower: {
    defaultMessage: "Girassol",
    id: "J0HAOj",
    description: "sunflower label",
  },
  SunnHemp: {
    defaultMessage: "Cânhamo",
    id: "b96NaI",
    description: "sunn hemp label",
  },
  Unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
  Wheat: {
    defaultMessage: "Trigo",
    id: "KcLVmD",
    description: "wheat label",
  },
  Millet: {
    defaultMessage: "Milheto",
    id: "qQ8qYm",
    description: "millet label",
  },
});
