import { createContext, useEffect, useRef, useState } from "react";
import RulerControl from "@mapbox-controls/ruler";
import { Map } from "mapbox-gl";

import { PlottedLayer } from "@type/mapbox";

import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_ENABLE_DRAG_ROTATE,
  MAPBOX_MAP_CENTER_COORDINATE,
  MAPBOX_MAP_STYLE,
  MAPBOX_MINIMUM_ZOOM,
  RULER_POSITION,
} from "./utils/constants";
import { MapboxContextType, MapboxProviderProps } from "./types";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox-controls/ruler/src/index.css";

export const MapboxContext = createContext<MapboxContextType>(
  {} as MapboxContextType
);

export const MapboxProvider = ({ children }: MapboxProviderProps) => {
  const [plottedLayers, setPlottedLayers] = useState<PlottedLayer[]>([]);
  const [isMapboxLoaded, setIsMapboxLoaded] = useState(false);

  const map = useRef<Map | null>(null);
  const mapContainer = useRef<string | HTMLElement>("");
  const isMapboxLoadingRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isMapboxLoaded) isMapboxLoadingRef.current = false;
  }, [isMapboxLoaded]);

  const onInitializeMap = () => {
    if (map.current) return;

    isMapboxLoadingRef.current = true;
    map.current = new Map({
      accessToken: MAPBOX_ACCESS_TOKEN,
      container: mapContainer.current,
      style: MAPBOX_MAP_STYLE,
      center: MAPBOX_MAP_CENTER_COORDINATE,
      zoom: MAPBOX_MINIMUM_ZOOM,
      dragRotate: MAPBOX_ENABLE_DRAG_ROTATE,
      minZoom: MAPBOX_MINIMUM_ZOOM,
    });

    map.current.on("load", () => {
      isMapboxLoadingRef.current = false;
      setIsMapboxLoaded(true);

      if (map.current) {
        map.current.addControl(
          new RulerControl({
            labelFormat: (value) => `${value.toFixed(3)} km`,
          }),
          RULER_POSITION
        );
      }
    });

    map.current.on("remove", () => {
      setIsMapboxLoaded(false);
      setPlottedLayers([]);
      map.current = null;
    });
  };

  const onDestroyMap = () => {
    if (map.current) {
      isMapboxLoadingRef.current = true;
      map.current.remove();
    }
  };

  const onAddPlottedLayer = (plottedLayerToAdd: PlottedLayer) => {
    setPlottedLayers((plottedLayers) => [...plottedLayers, plottedLayerToAdd]);
  };

  const onRemovePlottedLayer = (plottedLayerToRemove: PlottedLayer) => {
    setPlottedLayers((plottedLayers) =>
      plottedLayers.filter((layer) => layer.id !== plottedLayerToRemove.id)
    );
  };

  return (
    <MapboxContext.Provider
      value={{
        currentMap: map.current,
        mapContainer,
        onInitializeMap,
        onDestroyMap,
        plottedLayers,
        onAddPlottedLayer,
        onRemovePlottedLayer,
        isMapboxLoaded,
        isMapboxLoadingRef,
      }}
    >
      {children}
    </MapboxContext.Provider>
  );
};
