import { gql } from "@apollo/client";

export const CREATE_SERVICE_ORDER = gql`
  mutation CreateServiceOrder($input: CreateServiceOrderInput!) {
    createServiceOrder(input: $input) {
      id
      externalId
      applications {
        id
      }
    }
  }
`;

export const DELETE_SERVICE_ORDER = gql`
  mutation DeleteServiceOrder($deleteServiceOrderId: String!) {
    deleteServiceOrder(id: $deleteServiceOrderId)
  }
`;

export const UPDATE_SERVICE_ORDER = gql`
  mutation UpdateServiceOrder($input: UpdateServiceOrderInput!) {
    updateServiceOrder(input: $input) {
      id
    }
  }
`;

export const UPDATE_SERVICE_ORDER_STATUS = gql`
  mutation UpdateServiceOrderStatus(
    $updateCustomerServiceOrderStatusInput: UpdateCustomerServiceOrderStatusInput!
  ) {
    updateCustomerServiceOrderStatus(
      updateCustomerServiceOrderStatusInput: $updateCustomerServiceOrderStatusInput
    ) {
      id
    }
  }
`;

export const CREATE_PRE_FLIGHT = gql`
  mutation CreatePreFlight($createPreFlightInput: CreatePreFlightInput!) {
    createPreFlight(createPreFlightInput: $createPreFlightInput) {
      id
      applications {
        id
      }
    }
  }
`;

export const CREATE_POST_FLIGHT = gql`
  mutation CreatePostFlight($createPostFlightInput: CreatePostFlightInput!) {
    createPostFlight(createPostFlightInput: $createPostFlightInput) {
      id
      applications {
        id
      }
    }
  }
`;
