import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { client } from "@config/apollo-client";
import { SnackbarProvider } from "notistack";

import { ApolloProvider } from "@apollo/client";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { AuthProvider } from "@contexts/Authentication";
import { CustomerProvider } from "@contexts/Customer";
import { FeatureFlagsProvider } from "@contexts/FeatureFlags";
import { LanguageProvider } from "@contexts/Language";
import { MapboxProvider } from "@contexts/Mapbox";
import { PosthogProvider } from "@contexts/Posthog";
import { UnitMeasurementProvider } from "@contexts/UnitMeasurement";

import ErrorBoundary from "@components/ErrorBoundary";

import { theme } from "@theme/index";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <LanguageProvider>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Router>
            <FeatureFlagsProvider>
              <SnackbarProvider>
                <AuthProvider>
                  <CustomerProvider>
                    <PosthogProvider>
                      <MapboxProvider>
                        <UnitMeasurementProvider>
                          <CssBaseline />
                          <App />
                        </UnitMeasurementProvider>
                      </MapboxProvider>
                    </PosthogProvider>
                  </CustomerProvider>
                </AuthProvider>
              </SnackbarProvider>
            </FeatureFlagsProvider>
          </Router>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </LanguageProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
