import { gql } from "@apollo/client";

export const LIST_FIELDS_BY_CUSTOMER_AND_FILE = gql`
  query ListFieldsByCustomerAndFile($input: ListFieldsByCustomerAndFileInput) {
    fieldsByCustomerAndFile(input: $input) {
      id
      customerId
      farmId
      externalId
      totalArea
      farmHierarchy
      geometry {
        type
        coordinates
      }
      areaAccuracy
    }
  }
`;

export const LIST_FIELDS = gql`
  query ListFields($fieldsInput: ListFieldsInput!) {
    fields(input: $fieldsInput) {
      fields {
        id
        customerId
        farmId
        externalId
        totalArea
        farmHierarchy
        geometry {
          type
          coordinates
        }
      }
      paging {
        offset
        limit
        total
      }
    }
  }
`;
